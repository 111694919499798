<template>
  <v-row class="pt-16 mt-8" style="width: 100vw">
    <v-col>
      <div class="mx-8 float-right" align="center" style="width:450px">
        <v-img
            max-width="450px"
            src="@/assets/prices.jpg"
            contain
            rounded
            class="rounded mx-8"
        >
        </v-img>
      </div>

    </v-col>
    <v-col>
      <div style="max-width: 450px" class="pt-10">
        <h1><span style="font-weight: 300">{{$t('prices.title')}}</span> {{$t('prices.title_bold')}}</h1>
        <p class="body-1 py-4">{{$t('prices.subtitle')}}</p>
        <v-btn
            href="/createevent"
            color="#FF4B5B"
            dark
            elevation="0"
            class="create-event-btn"
            rounded
            x-large
        >
          {{ $t('prices.button') }}
        </v-btn>
      </div>

    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "PricesHeader"
}
</script>

<style scoped>

</style>